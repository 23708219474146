exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-advertise-jsx": () => import("./../../../src/pages/advertise.jsx" /* webpackChunkName: "component---src-pages-advertise-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-brand-jsx": () => import("./../../../src/pages/brand.jsx" /* webpackChunkName: "component---src-pages-brand-jsx" */),
  "component---src-pages-consult-jsx": () => import("./../../../src/pages/consult.jsx" /* webpackChunkName: "component---src-pages-consult-jsx" */),
  "component---src-pages-consult-profile-jsx": () => import("./../../../src/pages/consult-profile.jsx" /* webpackChunkName: "component---src-pages-consult-profile-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-product-jsx": () => import("./../../../src/pages/product.jsx" /* webpackChunkName: "component---src-pages-product-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-brand-jsx": () => import("./../../../src/templates/brand.jsx" /* webpackChunkName: "component---src-templates-brand-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */)
}

